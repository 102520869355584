<template>
  <div class="pb-16 fill-height">

    <template v-for="(category, j ) in rootCategories" >
      <div :key="'category' + j">
        <h3 :class="[{'mb-5' : childCategories(category.value).length === 0}]"> {{ category.text }}</h3>
        <!-- Single Category courses DESKTOP-->
        <div v-if="!SM" >
          <v-sheet :color="wsLIGHTCARD" v-for="(item,i) in categoryCourses(category.value)" :key="'course' + i + j" max-height="240" class="wsRoundedHalf mb-6 d-flex">

            <div>
              <v-img transition="xxx" v-if="item.img" class="wsRoundedHalfLeft" height="240" width="274"  :src="item.img" ></v-img>
              <v-img transition="xxx" v-else class="wsRoundedHalfLeft" height="240" width="274"  src="@/assets/img/course_blank.jpg" ></v-img>
            </div>
            <div style="width: 100%;" class="pa-6">
              <div class="d-flex justify-space-between">
                <h3 style="font-weight: 700">{{  item.name }}</h3>
                <h3 v-if="!HAS_ONLINE_PAYMENT && item.payed">
                  {{ !item.has_payment_plans ? item.price : ( $t('priceFrom') + ' ' + lowestPrice ) }} {{ BUSINESS_CURRENCY }}
                </h3>
              </div>

              <h5 :style="`color : ${wsACCENT}`" class="cut-text font-weight-medium mt-3">
                {{item.short_description }}
              </h5>

              <view-button-course-enroll
                  :display="triggerDisplayEnroll[item.uuid]"
                  :entity="item"
                  no-portal
              >
                <v-btn @click="triggerDisplayEnroll[item.uuid]++"  :color="wsATTENTION"
                       elevation="0"
                       dark class="noCaps mt-5">
                  <v-icon v-if="item.payed"  class="mr-2">mdi-basket-plus</v-icon>
                  <v-icon v-else class="mr-2">mdi-check-circle-outline</v-icon>
                  <span v-if="item.payed">{{ purchaseText }}</span>
                  <span v-if="!item.payed">{{ $t('Enroll') }} <v-icon>mdi-chevron-right</v-icon></span>
                </v-btn>
              </view-button-course-enroll>

              <v-btn
                  :to="businessPublicLink('course/' + item.uuid )"
                  :color="wsACCENT" text class="noCaps mt-5">
                {{ $t('CourseDescription') }}
                <v-icon>mdi-arrow-top-right-bold-box-outline</v-icon>
              </v-btn>
            </div>

          </v-sheet>
        </div>
        <!-- Single Category courses mobile-->
        <div v-if="SM"  >
          <v-sheet :color="wsLIGHTCARD" v-for="(item,i) in categoryCourses(category.value)" width="100%" :key="'course' + i + j"   class="wsRoundedHalf mb-6">
            <div style="width: 100%">
              <v-img v-if="item.img" class="wsRoundedHalfTop" height="200" width="auto"   :src="item.img"  />
              <v-img v-else class="wsRoundedHalfTop" height="200" width="auto"  src="@/assets/img/course_blank.jpg" />
            </div>
            <div style="width: 100%;" class="pa-3">

              <h4 style="font-weight: 700">{{  item.name }}</h4>
              <h5 :style="`color : ${wsACCENT}`" class="cut-text font-weight-medium ">
                {{item.short_description }}
              </h5>



              <v-btn
                  :to="businessPublicLink('course/' + item.uuid )"
                  :color="wsACCENT"
                  class="noCaps mt-5"
                  outlined
                  block
              >
                {{ $t('CourseDescription') }}
                <v-icon>mdi-arrow-top-right-bold-box-outline</v-icon>

              </v-btn>

              <view-button-course-enroll
                  :display="triggerDisplayEnroll[item.uuid]"
                  :entity="item"
              >
                <v-btn @click="triggerDisplayEnroll[item.uuid]++"  :color="wsATTENTION"
                       elevation="0" block
                       dark class="noCaps mt-5">
                  <v-icon v-if="item.payed"  class="mr-2">mdi-basket-plus</v-icon>
                  <v-icon v-else class="mr-2">mdi-check-circle-outline</v-icon>
                  <span v-if="item.payed">{{ purchaseText }}</span>
                  <span v-if="!item.payed">{{ $t('Enroll') }} <v-icon>mdi-chevron-right</v-icon></span>
                </v-btn>
              </view-button-course-enroll>


            </div>



          </v-sheet>
        </div>

        <template v-for="(child,k) in childCategories(category.value)">
          <div :key="'child_category' + j + k">
            <h4 v-if="categoryCourses(child.value).length > 0" class="mb-5"> {{ child.text }}</h4>
            <div v-if="!SM" >
              <v-sheet :color="wsLIGHTCARD" v-for="(item,i) in categoryCourses(child.value)" :key="'course' + i + j + k" max-height="240" class="wsRoundedHalf mb-6 d-flex">

                <div>
                  <v-img v-if="item.img" class="wsRoundedHalfLeft" height="240" width="274"  :src="item.img" ></v-img>
                  <v-img v-else class="wsRoundedHalfLeft" height="240" width="274"  src="@/assets/img/course_blank.jpg" ></v-img>
                </div>

                <div style="width: 100%;" class="pa-6">
                  <div class="d-flex justify-space-between">
                    <h3 style="font-weight: 700">{{  item.name }}</h3>
                    <h3 v-if="!HAS_ONLINE_PAYMENT && item.payed">
                      {{ !item.has_payment_plans ? item.price : ( $t('priceFrom') + ' ' + lowestPrice ) }} {{ BUSINESS_CURRENCY }}
                    </h3>
                  </div>

                  <h5 :style="`color : ${wsACCENT}`" class="cut-text font-weight-medium mt-3">
                    {{item.short_description }}
                  </h5>

                  <view-button-course-enroll
                      :display="triggerDisplayEnroll[item.uuid]"
                      :entity="item"
                  >
                    <v-btn @click="triggerDisplayEnroll[item.uuid]++"  :color="wsATTENTION"
                           elevation="0"
                           dark class="noCaps mt-5">
                      <v-icon v-if="item.payed"  class="mr-2">mdi-basket-plus</v-icon>
                      <v-icon v-else class="mr-2">mdi-check-circle-outline</v-icon>
                      <span v-if="item.payed">{{ purchaseText }}</span>
                      <span v-if="!item.payed">{{ $t('Enroll') }} <v-icon>mdi-chevron-right</v-icon></span>
                    </v-btn>
                  </view-button-course-enroll>

                  <v-btn
                      :to="businessPublicLink('course/' + item.uuid )"
                      :color="wsACCENT" text class="noCaps mt-5">
                    {{ $t('CourseDescription') }}
                    <v-icon>mdi-arrow-top-right-bold-box-outline</v-icon>
                  </v-btn>
                </div>

              </v-sheet>
            </div>
            <div v-if="SM"  >
              <v-sheet :color="wsLIGHTCARD" v-for="(item,i) in categoryCourses(child.value)" width="100%" :key="'course' + i + j + k"   class="wsRoundedHalf mb-6">
                <div style="width: 100%">
                  <v-img v-if="item.img" class="wsRoundedHalfTop" height="200" width="auto"   :src="item.img"  />
                  <v-img v-else class="wsRoundedHalfTop" height="200" width="auto"  src="@/assets/img/course_blank.jpg" />
                </div>
                <div style="width: 100%;" class="pa-3">

                  <h4 style="font-weight: 700">{{  item.name }}</h4>
                  <h5 :style="`color : ${wsACCENT}`" class="cut-text font-weight-medium ">
                    {{item.short_description }}
                  </h5>

                  <v-btn
                      :to="businessPublicLink('course/' + item.uuid )"
                      :color="wsACCENT"
                      class="noCaps mt-5"
                      outlined
                      block
                  >
                    {{ $t('CourseDescription') }}
                    <v-icon>mdi-arrow-top-right-bold-box-outline</v-icon>
                  </v-btn>

                  <view-button-course-enroll
                      :display="triggerDisplayEnroll[item.uuid]"
                      :entity="item"

                  >
                    <v-btn @click="triggerDisplayEnroll[item.uuid]++"  :color="wsATTENTION"
                           elevation="0" block
                           dark class="noCaps mt-5">
                      <v-icon v-if="item.payed"  class="mr-2">mdi-basket-plus</v-icon>
                      <v-icon v-else class="mr-2">mdi-check-circle-outline</v-icon>
                      <span v-if="item.payed">{{ purchaseText }}</span>
                      <span v-if="!item.payed">{{ $t('Enroll') }} <v-icon>mdi-chevron-right</v-icon></span>
                    </v-btn>
                  </view-button-course-enroll>


                </div>



              </v-sheet>
            </div>
          </div>
        </template>

      </div>
    </template>

    <v-sheet color="transparent" height="100" />


    <!-- Enrollment Success Dialog on Course -->
    <wsDialog v-model="displayEnrollSuccess"
              :loading="$store.state.LOADING"
              @save="goToCourse"
              :title="displayApplicationReceivedText ? $t('CourseApplicationSuccess') : $t('EnrollmentSuccess')"
              :cancel-text="$t('Close')"
              :show-save="!displayApplicationReceivedText"
              :saveText="!displayApplicationReceivedText ? $t('GotoCourse') : $t('Ok')">

      <h5 class="font-weight-medium text-center mb-3">{{ displayApplicationReceivedText ?   $t('CourseApplicationSuccessMessage') : $t('EnrollmentSuccessMessage') }}</h5>

    </wsDialog>

    <!-- Enroll on Course -->
    <wsDialog v-model="displayEnroll"
              width="700"
              :loading="$store.state.LOADING"
              @save="enrollOnCourse"
              :title="$t('EnrollOnCourse')"
              :saveText="$t('Enroll')">

      <!-- Scheduled course -->
      <div v-if="selectedCourse.scheduled">
        <div v-if="!SM">
          <h5 class="font-weight-medium mb-3">{{ $t('ChooseEnrollmentSchedule') }}:</h5>
          <v-simple-table >
            <thead>
            <tr>
              <th></th>
              <th></th>
              <th :style="`color : ${wsDARK}`"> {{ $t('Education Start') }}</th>
              <th :style="`color : ${wsDARK}`"> {{ $t('Education End') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(wave,i) in enrollmentWaves" :key="i"
                @click="enrollmentData.enrollment_wave_id = wave.uuid"
                class="pointer">
              <td>
                <v-btn @click="enrollmentData.enrollment_wave_id = wave.uuid"
                       :color="enrollmentData.enrollment_wave_id === wave.uuid ?  'green lighten-2' : 'grey' "
                       small icon
                       class="mt-1">
                  <v-icon small>{{ enrollmentData.enrollment_wave_id === wave.uuid ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline'}}</v-icon>
                </v-btn>
              </td>
              <td>
                <h5>{{ wave.name }}</h5>
              </td>

              <td>
                <v-chip class="my-1 mr-2" small dark :color="wsDARK"  >
                  <v-icon small class="mr-1" > mdi-calendar</v-icon>
                  <h4 class="font-weight-medium">{{wave.education_start }}</h4>
                </v-chip>
              </td>
              <td>
                <v-chip class="my-1 mr-2" small outlined   >
                  <v-icon small class="mr-1" > mdi-calendar</v-icon>
                  <h4 class="font-weight-medium">{{wave.education_end }}</h4>
                </v-chip>
              </td>
            </tr>
            </tbody>
          </v-simple-table>
        </div>
        <div v-else>
          <h5 class="font-weight-medium mb-3 mt-6 text-center">{{ $t('ChooseEnrollmentSchedule') }}:</h5>
          <v-sheet  v-for="(wave,i) in enrollmentWaves" :key="i"
                    @click="enrollmentData.enrollment_wave_id = wave.uuid"
                    :dark="enrollmentData.enrollment_wave_id === wave.uuid"
                    :color="enrollmentData.enrollment_wave_id === wave.uuid ? wsACCENT : ''"
                    class="pointer pa-3 mt-3 wsRoundedHalf" outlined>
            <h5 class="text-center">{{ wave.name }}</h5>
            <div class="d-flex justify-center">
              <div>
                <ws-chip icon="mdi-calendar" class="my-1 mr-2" small dark :color="enrollmentData.enrollment_wave_id !== wave.uuid ? wsACCENT : ''"  >
                  <h5 class="font-weight-medium">{{ $t('EducationStart') }}: {{wave.education_start }}</h5>
                </ws-chip>
                <ws-chip icon="mdi-calendar" class="my-1 mr-2" small dark :color="enrollmentData.enrollment_wave_id !== wave.uuid ? wsACCENT : ''"  >
                  <h5 class="font-weight-medium">{{ $t('EducationEnd') }}: {{wave.education_end }}</h5>
                </ws-chip>
              </div>

            </div>

          </v-sheet>
        </div>

      </div>


    </wsDialog>
    <!-- Course Purchase -->
    <wsDialog v-model="displayPurchase"
              width="900"
              :loading="$store.state.LOADING"
              @save="purchaseCourse"
              :title="$t('PurchaseCourse')"
              :saveText="purchaseText">

      <div class="pb-6">
        <div v-if="!SM">
          <v-simple-table >
            <tbody>
            <tr style="background: transparent" class="pointer"  >
              <td class="px-0 pl-3 py-3" width="1">
                <v-img v-if="selectedCourse.img" class="wsRoundedHalf" height="120" width="200"  :src="selectedCourse.img" ></v-img>
                <v-img v-else class="wsRoundedHalf" height="100" width="200"  src="@/assets/img/course_blank.jpg" ></v-img>
              </td>
              <td valign="top" class="py-3">
                <h4>{{ selectedCourse.name }}</h4>
                <h5 class="font-weight-light mt-3">
                  {{ selectedCourse.short_description }}
                </h5>
              </td>
              <td valign="top" class="py-3" align="right"  width="150px">

                <h4 class="text-no-wrap" :style="`color: ${wsDARK}`">{{ singlePriceText }} </h4>
                <h5> {{ priceDescriptionSubscriptionText }}</h5>

              </td>
            </tr>
            </tbody>
          </v-simple-table>
          <v-divider class="my-3" />
          <h4 class="text-right">{{ $t('Total') }}: {{ totalPriceText }} </h4>
        </div>
        <!-- Mobile View-->
        <div v-else>

          <v-img v-if="selectedCourse.img" class="wsRoundedHalf" height="180"   :src="selectedCourse.img" ></v-img>
          <v-img v-else class="wsRoundedHalf" height="180"   src="@/assets/img/course_blank.jpg" ></v-img>

          <h4 class="mt-3">{{ selectedCourse.name }}</h4>
          <v-divider class="my-1"></v-divider>
          <h4 class="text-right font-weight-light">{{ $t('Total') }}: <span class="font-weight-bold">{{ totalPriceText }} </span></h4>

        </div>

        <!-- Subscription course -->
        <div  class="mt-6 " v-if="selectedCourse.is_subscription">
          <h4 :class="[{'text-center' : SM}]" class="mb-5" >{{ $t('ChooseSubscription') }}</h4>
          <ws-select :items="timePeriodsSelect" v-model="enrollmentData.quantity" :placeholder="$t('Period')">  </ws-select>
        </div>

        <!-- Payment Plans course -->
        <div v-if="selectedCourse.has_payment_plans">
          <h5 class="font-weight-medium my-3">{{ $t('ChoosePaymentPlan') }}:</h5>
          <v-simple-table>
            <tbody>
            <tr v-for="(plan,i) in selectedCourse.payment_plans" :key="i"
                @click="enrollmentData.payment_plan_id = plan.value"
                class="pointer">
              <td width="10px">
                <v-btn @click="enrollmentData.payment_plan_id = plan.value"
                       :color="enrollmentData.payment_plan_id === plan.value ?  'green lighten-2' : 'grey' "
                       small icon
                       class="mt-1">
                  <v-icon small>{{ enrollmentData.payment_plan_id === plan.value ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline'}}</v-icon>
                </v-btn>
              </td>
              <td>
                <h5>{{ plan.text }}</h5>
              </td>

              <td width="10px" class="text-no-wrap" align="center">
                <h4>{{ plan.price }} {{ BUSINESS_CURRENCY }}</h4>
              </td>
            </tr>
            </tbody>
          </v-simple-table>
        </div>

        <!-- Scheduled course -->
        <div v-if="selectedCourse.online && selectedCourse.scheduled">
          <div v-if="!SM">
            <h5 class="font-weight-medium mt-3">{{ $t('ChooseEnrollmentSchedule') }}:</h5>
            <v-simple-table >
              <thead>
              <tr>
                <th></th>
                <th></th>
                <th :style="`color : ${wsDARK}`"> {{ $t('Education Start') }}</th>
                <th :style="`color : ${wsDARK}`"> {{ $t('Education End') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(wave,i) in enrollmentWaves" :key="i"
                  @click="enrollmentData.enrollment_wave_id = wave.uuid"
                  class="pointer">
                <td>
                  <v-btn @click="enrollmentData.enrollment_wave_id = wave.uuid"
                         :color="enrollmentData.enrollment_wave_id === wave.uuid ?  'green lighten-2' : 'grey' "
                         small icon
                         class="mt-1">
                    <v-icon small>{{ enrollmentData.enrollment_wave_id === wave.uuid ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline'}}</v-icon>
                  </v-btn>
                </td>
                <td>
                  <h5>{{ wave.name }}</h5>
                </td>

                <td>
                  <v-chip class="my-1 mr-2" small dark :color="wsDARK"  >
                    <v-icon small class="mr-1" > mdi-calendar</v-icon>
                    <h4 class="font-weight-medium">{{wave.education_start }}</h4>
                  </v-chip>
                </td>
                <td>
                  <v-chip class="my-1 mr-2" small outlined   >
                    <v-icon small class="mr-1" > mdi-calendar</v-icon>
                    <h4 class="font-weight-medium">{{wave.education_end }}</h4>
                  </v-chip>
                </td>
              </tr>
              </tbody>
            </v-simple-table>
          </div>
          <div v-else>
            <h5 class="font-weight-medium mb-3 mt-6 text-center">{{ $t('ChooseEnrollmentSchedule') }}:</h5>
            <v-sheet  v-for="(wave,i) in enrollmentWaves" :key="i"
                      @click="enrollmentData.enrollment_wave_id = wave.uuid"
                      :dark="enrollmentData.enrollment_wave_id === wave.uuid"
                      :color="enrollmentData.enrollment_wave_id === wave.uuid ? wsACCENT : ''"
                      class="pointer pa-3 mt-3 wsRoundedHalf" outlined>
              <h5 class="text-center">{{ wave.name }}</h5>
              <div class="d-flex justify-center">
                <div>
                  <ws-chip icon="mdi-calendar" class="my-1 mr-2" small dark :color="enrollmentData.enrollment_wave_id !== wave.uuid ? wsACCENT : ''"  >
                    <h5 class="font-weight-medium">{{ $t('EducationStart') }}: {{wave.education_start }}</h5>
                  </ws-chip>
                  <ws-chip icon="mdi-calendar" class="my-1 mr-2" small dark :color="enrollmentData.enrollment_wave_id !== wave.uuid ? wsACCENT : ''"  >
                    <h5 class="font-weight-medium">{{ $t('EducationEnd') }}: {{wave.education_end }}</h5>
                  </ws-chip>
                </div>

              </div>

            </v-sheet>
          </div>
        </div>

      </div>

    </wsDialog>

  </div>
</template>

<script>

import {mapActions} from "vuex";
import viewButtonCourseEnroll from "@/components/AvalonEditor/View/elements/button/viewButtonCourseEnroll";

export default {
  name: "PageCourses",
  components : {
    viewButtonCourseEnroll
  },
  data() {
    return {
      loading : false,
      items : [],
      categories : [],

      selectedCourse : {},

      enrollmentData : {},
      displayEnrollSuccess    :  false,
      displayPurchase : false,
      displayEnroll : false,
      enrollmentWaves : [],
      displayApplicationReceivedText : false,

      triggerDisplayEnroll : {}
    }
  },
  computed : {
    timePeriodsSelect() {
      if ( !this.selectedCourse.time_periods_select) {
        return []
      }
      let items = []
      this.selectedCourse.time_periods_select.forEach((period)=>{
        let type = this.selectedCourse.time_period_type
        let quantity = type === 'custom' ? (period * this.selectedCourse.time_period_days ) : period
        type = type === 'custom' ? 'day' : type

        items.push({
          text : this.$tc(type , quantity),
          value : period
        })
      })
      return items
    },

    purchaseText() {
      return this.HAS_ONLINE_PAYMENT ?  this.$t('Buy') : this.$t('ApplyOnCourse')
    },
    lowestPrice() {
      if ( this.selectedCourse.has_payment_plans && this.selectedCourse.payment_plans.length > 0  ) {
        let prices = this.selectedCourse.payment_plans.map(el => el.price )
        return Math.min(...prices)
      }
      return 0;
    },
    singlePriceText() {

      let price = this.selectedCourse.price
      if ( this.selectedCourse.has_payment_plans) {
        if (this.enrollmentData.payment_plan_id) {
          let selectedCourse = this.selectedCourse.payment_plans.find(el => el.value === this.enrollmentData.payment_plan_id)
          price = selectedCourse.price
        } else {
          return this.$t('SelectPaymentPlan')
        }
      }

      return price + ' ' + this.BUSINESS_CURRENCY

    },
    totalPriceText() {
      if ( this.selectedCourse.has_payment_plans && !this.enrollmentData.payment_plan_id ) {
        return this.$t('SelectPaymentPlan')
      }
      return this.totalPrice + ' ' + this.BUSINESS_CURRENCY
    },
    priceDescriptionSubscriptionText() {
      if ( !this.selectedCourse.is_subscription ) {
        return ''
      }

      if ( this.selectedCourse.subscription_type === 'time_period' ) {
        if ( this.selectedCourse.time_period_type === 'month' ) {
          return ' ' + this.$tc('perMonths' , 1)
        }
        if ( this.selectedCourse.time_period_type === 'year' ) {
          return ' ' + this.$tc('perYears' , 1)
        }
        if ( this.selectedCourse.time_period_type === 'custom' ) {
          return ' ' + this.$tc('perDays' , this.selectedCourse.time_period_days)
        }
      }

      return ''

    },
    totalPrice() {

      let price = this.selectedCourse.price
      if ( this.selectedCourse.has_payment_plans) {
        if ( this.enrollmentData.payment_plan_id ) {
          let selectedCourse = this.selectedCourse.payment_plans.find(el => el.value === this.enrollmentData.payment_plan_id)
          price = selectedCourse.price
        } else {
          return this.$t('SelectPaymentPlan')
        }
      }

      if ( this.enrollmentData.quantity ) {
        price = price * this.enrollmentData.quantity
      }

      return price

    },
    
    rootCategories() {
      if ( this.categories.length === 0 ) {
        return [{text: '', value: 'all'}]
      }
      let items = this.categories.filter( el => el.parent === null )
      items.push({text: this.$t('UnsortedCourses'), value: 'unsorted'} )
      return items
    },
  },
  methods : {
    ...mapActions('courses', [
      'GET_ACTIVE_COURSES',
      'GET_COURSE',
      'ENROLL_ON_COURSE']),

    ...mapActions('courses', [  'GET_ACTIVE_COURSES',  'GET_COURSE_PUBLIC' , 'CHECK_STUDENT_ENROLLMENT', 'GET_COURSE_FULL', 'ENROLL_ON_COURSE' , 'GET_ENROLLMENT_WAVES_STUDENT' ]),
    ...mapActions('cart', [  'ORDER_COURSE'  ]),
    ...mapActions('liqpay', ['GET_BUSINESS_BUTTON']),
    ...mapActions('monobank', ['GET_MONO_CHECKOUT_URL']),
    ...mapActions('fondy', ['GET_FONDY_CHECKOUT_URL']),
    ...mapActions('wayforpay', ['GET_WAYFORPAY_CHECKOUT_URL']),


    goToCourse() {
      this.$router.push(this.businessDashLink('courses/' + this.selectedCourse.uuid + '/student'))
    },
    async displayBuyCourse(item) {
      this.selectedCourse = item

      if ( this.selectedCourse.scheduled ) {
        this.enrollmentData = {
          uuid : this.selectedCourse.uuid,
          enrollment_wave_id : null
        }
        this.enrollmentWaves = await this.GET_ENROLLMENT_WAVES_STUDENT(this.selectedCourse.uuid) || [] ;
      }
      if ( this.selectedCourse.has_payment_plans ) {
        this.enrollmentData.payment_plan_id = null
      }
      this.enrollmentData = JSON.parse(JSON.stringify(this.enrollmentData))

      this.displayPurchase = true
    },
    async purchaseCourse() {

      let data = {
        course_id : this.selectedCourse.uuid,
        origin : this.DEVICE
      }

      if (  this.selectedCourse.online && this.selectedCourse.scheduled ) {
        if ( !this.enrollmentData.enrollment_wave_id ) {
          return this.notify(this.$t('PleaseSelectEnrollmentDate'))
        }
        data.enrollment_wave_id = this.enrollmentData.enrollment_wave_id
      }

      if (  this.selectedCourse.payed && this.selectedCourse.has_payment_plans ) {
        if ( !this.enrollmentData.payment_plan_id ) {
          return this.notify(this.$t('PleaseSelectPaymentPlan'))
        }
        data.payment_plan_id = this.enrollmentData.payment_plan_id
      }

      if ( this.enrollmentData.quantity ) {
        data.quantity = this.enrollmentData.quantity
      }

      let result = await this.ORDER_COURSE(data)
      if ( !result ) { return this.notify( this.$t('NetworkError') ) }

      this.removeCourse(this.selectedCourse.uuid)



      this.processOnlinePayment(result)
      this.displayPurchase = false
      this.displayEnrollSuccess = true;


    },

    async processOnlinePayment(order) {
      if ( !this.HAS_ONLINE_PAYMENT ) {
        this.displayPurchase = false
        this.displayEnrollSuccess = true;
        return
      }
      if ( this.GET_ONLINE_PAYMENT === 'online_liqpay') {
        this.getLiqpayButton(order)
      }
      if ( this.GET_ONLINE_PAYMENT === 'online_mono') {
        this.getMonoUrl(order)
      }
      if ( this.GET_ONLINE_PAYMENT === 'online_fondy') {
        this.getFondyUrl(order)
      }
      if ( this.GET_ONLINE_PAYMENT === 'online_wayforpay') {
        this.getWayforpayUrl(order)
      }
    },

    async getMonoUrl(order) {
      let data = {
        order_number: order.order_number,
        return_url: location.href
      }

      let result = await this.GET_MONO_CHECKOUT_URL(data)
      if ( !result ) {
        return
      }
      let a = document.createElement('a')
      a.href = result.pageUrl
      a.click()
      a.remove()

    },
    async getWayforpayUrl(order) {
      let data = {
        order_number: order.order_number,
        return_url: location.href
      }

      let result = await this.GET_WAYFORPAY_CHECKOUT_URL(data)
      if ( !result ) {
        return
      }
      this.WAYFORPAY_INVOICE(result)

    },
    async getFondyUrl(order) {
      let data = {
        order_number: order.order_number,
        return_url: location.href
      }

      let result = await this.GET_FONDY_CHECKOUT_URL(data)
      if ( !result ) {
        return
      }
      let a = document.createElement('a')
      a.href = result.url
      a.click()
      a.remove()

    },
    async getLiqpayButton(order) {

      let data = {
        order_number: order.order_number,
        total: this.totalPrice,
        return_url: location.href
      }
      let button = await this.GET_BUSINESS_BUTTON(data)
      if ( !button || button === true ) {
        return this.notify(this.$t('NetworkError'))
      }
      this.LIQPAY_checkout(button)
      this.displayEnroll = false
      this.displayEnrollSuccess = true

    },


    handleEnrollOnCourse(item) {
      this.selectedCourse = item

      this.enrollmentData = {
        uuid : item.uuid
      }

      if ( !item.online ) {
        this.enrollOnCourse()
      } else {
        this.displayEnrollWindow()
      }

    },
    async displayEnrollWindow() {

      if ( this.selectedCourse.online && this.selectedCourse.scheduled ) {
        this.enrollmentData = {
          uuid : this.selectedCourse.uuid,
          enrollment_wave_id : null
        }
        this.enrollmentWaves = await this.GET_ENROLLMENT_WAVES_STUDENT(this.selectedCourse.uuid) || [] ;
      }

      this.displayEnroll = true
    },
    async enrollOnCourse( displayEnroll = true) {


      if ( this.selectedCourse.online && this.selectedCourse.scheduled ) {
        if ( !this.enrollmentData.enrollment_wave_id ) {
          return this.notify(this.$t('PleaseSelectEnrollmentDate'))
        }
      }

      let result = await this.ENROLL_ON_COURSE(this.enrollmentData);
      if ( !result ) { return this.notify( this.$t('NetworkError') ) }


      if ( displayEnroll ) {
        this.displayEnroll = false
        this.displayEnrollSuccess = true
      }
      this.removeCourse(this.selectedCourse.uuid)

    },


    // technical
    removeCourse(uuid) {
      let index = this.items.findIndex( el=> el.uuid === uuid )
      if ( index === -1 ) {
        return
      }
      this.items.splice(index , 1 )
    },
    categoryCourses(selectedCategory) {
      let items = this.items
      if (selectedCategory !== 'all') {
        let category = selectedCategory !== 'unsorted' ? selectedCategory : null
        items = items.filter( el => el.category_id === category )
      }
      return items
    },
    childCategories(parentId) {
      return this.categories.filter(el => el.parent === parentId )
    },

    // Page init
    async initPage(){

      let result = await this.GET_ACTIVE_COURSES();
      if ( !result ) { this.notify(this.$t('ConnectionError'))}
      result.courses.forEach(course => {
        this.triggerDisplayEnroll[course.uuid] = 0
      })
      this.triggerDisplayEnroll = Object.assign({} , this.triggerDisplayEnroll)
      this.items = result.courses;
      this.categories = result.categories

    }
  },

  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>
.cut-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>